<template>
  <v-app>
    <v-data-table
      ref="pendingOrdersTable"
      :headers="headers"
      :items="loadings"
      :items-per-page="10"
      :search="search"
      :loading="isLoaded"
      loading-text="Fetching Data... Please wait."
      class="elevation-1 cranberry"
      v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
      :key="anIncreasingNumber"
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
    <template v-slot:item.Certainty="{ item }">
      <v-chip :color="getColor(item.calories)" dark> </v-chip>
    </template>
  </v-app>
</template>

<style>
.cranberry {
  margin-top: 2%;
}
</style>

<script>
import Sortable from "sortablejs";
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['allTransportData']), 
    },
  data() {
    return {
      transport_order_id: "",
      anIncreasingNumber: 1,
      search: "",
      headers: [
        {
          text: "Certainty",
          align: "start",
          sortable: false,
          value: "",
        },
        { text: "Order #", value: "display_number" },
        { text: "Client Ref.", value: "client_id" },
        { text: "MT", value: "" },
        { text: "MA", value: "" },
        { text: "DD", value: "" },
        { text: "Urgency", value: "" },
        { text: "LB", value: "" },
        { text: "Date", value: "date_load" },
        { text: "Ctry", value: "country_load" },
        { text: "Zip code", value: "zip_load" },
        { text: "Loading", value: "street_1_load" },
        { text: "Name (from)", value: "name_load" },
        { text: "Date", value: "date_deli" },
        { text: "Ctry", value: "country_deli" },
        { text: "Zip code", value: "zip_deli" },
        { text: "Unloading", value: "street_1_deli" },
        { text: "Name (to)", value: "name_deli" },
        { text: "Volume", value: "loading_meters" },
        { text: "L/C", value: "" },
        { text: "Unit", value: "unit_type" },
        { text: "Gr wt", value: "grwt" },
        { text: "ADR", value: "dangerous_goods" },
      ],
      transportData: [],
      client_ref: "",
      isLoaded: true,
      loadings: [],
      display_number: "",
    };
  },
  
  mounted() {
    //console.log(typeof this.allTransportData);
    if(Object.keys(this.allTransportData).length > 0){
      this.loadLoading(this.allTransportData);
      //console.log('123');
      return
    }
    this.getTransportData();
  },
  methods: {
    ...mapActions(['fetchTransportData']),

    handleClick(value) {
      // let orderID = {
      //   id: Math.ceil(Math.random()*10),
      //   description: value.display_number
      // };
      this.$router.push({
        name: "InProgress", //use name for router push
        params: {transport_order_id:value.id}
      });
      console.log(value.id)
    },
    getTransportData() {
      this.transportData = [];
      this.loadings = [];
      this.$api
        .get("/transport_file/get_by_status/pending", {
          headers: {
            "X-Authorization": this.$session.get("token"),
          },
        })
        .then((res) => {
          this.transportData = res.data;
          this.loadLoading(this.transportData);
          this.fetchTransportData(res.data);
          //console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getColor(certainty) {
      if (certainty < 500) return "red";
      else if (certainty > 800) return "orange";
      else return "green";
    },
    loadLoading(data) {
      for (const key in data) {
        const temp = {
          display_number: data[key].display_number,
          id: data[key].id,
          client_id: data[key].client.client_identifier,
        };
        for (const loadKey in data[key].activities) {
          for (const goodsKey in data[key].activities[loadKey]
            .goods) {
            temp.grwt =
              data[key].activities[loadKey].goods[
                goodsKey
              ].gross_weight;
            temp.dangerous_goods =
              data[key].activities[loadKey].goods[
                goodsKey
              ].dangerous_goods;
            temp.loading_meters =
              data[key].activities[loadKey].goods[
                goodsKey
              ].loading_meters;
            temp.unit_type =
              data[key].activities[loadKey].goods[
                goodsKey
              ].unit_type;
          }
          if (
            data[key].activities[loadKey].activity_type == "Load"
          ) {
            temp.street_1_load =
              data[key].activities[loadKey].address.street_1;
            temp.date_load = data[key].activities[loadKey].date;
            temp.country_load =
              data[key].activities[loadKey].address.country;
            temp.zip_load =
              data[key].activities[loadKey].address.zipcode;
            temp.name_load = data[key].client.name;
          }
          if (
            data[key].activities[loadKey].activity_type == "Deli"
          ) {
            temp.street_1_deli =
              data[key].activities[loadKey].address.street_1;
            temp.date_deli = data[key].activities[loadKey].date;
            temp.country_deli =
              data[key].activities[loadKey].address.country;
            temp.zip_deli =
              data[key].activities[loadKey].address.zipcode;
            temp.name_deli = data[key].client.name;
          }
        }
        //console.log(temp);
        this.isLoaded = false;
        this.loadings.push(temp);
      }
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
  },
  directives: {
    "sortable-table": {
      inserted: (el, binding) => {
        el.querySelectorAll("th").forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          //watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(
          el.querySelector("tr"),
          binding.value ? { ...binding.value, handle: ".sortHandle" } : {}
        );
      },
    },
  },
};
</script>
