<template>
  <v-app>
    <div class="header">
      <img src="../assets/logo.svg" alt="" class="logo">
      <h2>Logichainge test platform</h2>
    </div>
    <v-container fluid>
      <v-row cols="12">
        <v-col sm="1" md="1" lg="1" no-gutters> </v-col>
        <v-col sm="6" md="5" lg="5">
          <FileInfoCard />

        </v-col>
        <v-spacer></v-spacer>

        <v-col sm="6" md="6" lg="6">
          <!-- <div class="loading-circle-container">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div> -->
          <img :src="this.transportData.tr_file_image_ref" class="invoice">
          <img src="../assets/axxor.png" class="invoice" v-if="!this.transportData.tr_file_image_ref">
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import FileInfoCard from "../components/InProgressComponents/fileInfoCard.vue"
export default {
  name: "InProgress",
  components: {
    FileInfoCard
  },
  data() {
    return {
      transportData: []
    }
  },
  mounted() {
    this.getTransportData();
    if (this.$route.params.transport_order_id == null) {
      console.log("error loading data");
      this.$router.push("orderlist")
    }
    else {
      this.transport_order_id = this.$route.params.transport_order_id;
      this.getTransportData();
      console.log(this.$route.params.transport_order_id);
    }
  },
  methods: {
    getTransportData() {
      this.transportData = [];
      this.loadedOrderInfo = {};
      this.$api
        .get("/transport_file/" + this.transport_order_id, {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            "X-Authorization": this.$session.get("token")
          },
        })
        .then((res) => {
          this.transportData = res.data;
          this.loadLoading();
          console.log(this.loadedOrderInfo.tr_file_image_ref);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadLoading() {
      this.loadedOrderInfo = this.transportData;
    },
  }

};
</script>

<style scoped lang="scss">
.loading-circle-container {
  z-index: 100;
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding-top: 300px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);
  border-radius: 10px;
}
.invoice {
  box-shadow: -1px 1px 14px 1px rgba(0, 0, 0, 0.77);
  max-width: 90%;
  position: sticky;
  top: 20px;
}

.header {
  width: 100%;
  height: 60px;
  background-color: var(--brand-color);
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-left: 60px;
}

h2 {
  color: var(--brand-white);
  margin-left: 100px;
}
</style>