<template>
  <v-row align="center"
      justify="center">
    <v-col cols="3">
      <label>{{btnGroupName}}</label>
    </v-col>
    <v-col>
      <v-btn-toggle v-model="toggle_exclusive"
                    mandatory>
        <v-btn block>
          {{ btn1Label }}
        </v-btn>
        <v-btn block
               >
           {{ btn2Label }}
        </v-btn>
        <v-btn block
               >
         {{ btn3Label }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
        btnGroupName: {
      type: String,
      default: () => "btnGroupNam",
    },
    btn1Label: {
      type: String,
      default: () => "btn1Label",
    },
    btn2Label: {
      type: String,
      default: () => "btn2Label",
    },
    btn3Label: {
      type: String,
      default: () => "btn3Label",
    }
  },
  data() {
    return {
      toggle_exclusive: undefined,
    }
  },
}
</script>

<style scoped>
.v-btn.v-size--default .label {
  font-weight: 500;

}
.v-btn-toggle .v-btn {
  margin-left: 0 !important;
  max-height: 40px;
}

.v-btn--block {
  min-width: 40% !important;
}
</style>