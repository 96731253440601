<template>
  <v-app>
    <div class="loading-circle-container" v-if="!this.loadedOrderInfo">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form id="dataValidationForm" ref="form" v-model="valid" lazy-validation>
      <v-col cols="12" sm="11" md="11" lg="12">
        <v-col>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" fab icon>
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7 mt-1">
              <h3>Transport Order #{{ transport_order_id }}</h3>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
        <v-row>
          <v-col>
            <v-text-field class="inputField" label="Client Name" name="ClientName" id="ClientName" for="ClientName"
              v-model="this.loadedOrderInfo.client_name" :rules="mandatory" required></v-text-field>
            <v-text-field class="clientContact" label="Client Contact" name="clientContact" id="clientContact"
              for="clientContact" v-model="this.loadedOrderInfo.contact_name" :rules="mandatory" required>
            </v-text-field>
            <v-text-field class="invoiceRef" label="Invoice Refrence" name="invoiceRef" id="invoiceRef" for="invoiceRef"
              v-model="this.loadedOrderInfo.invoice_reference" :rules="mandatory" required></v-text-field>
            <v-text-field class="inputField" label="File Type" name="fileType" id="fileType" for="fileType"
              v-model="this.loadedOrderInfo.file_type" :rules="mandatory" required></v-text-field>
            <ButtonGroup btnGroupName="Main Modality" btn1Label="Road" btn2Label="Train" btn3Label="Short-Sea" />
            <ButtonGroup btnGroupName="Service Level" btn1Label="Regular" btn2Label="Urgent" btn3Label="Intl" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- this v-for loop goes through the activities and assigns the information to the fields.
            The key that is used is the id of the acitivity.
            -->
            <div v-for="activities in this.loadedOrderInfo.activities" :key="activities.id">
              <v-item-group>
                <v-divider></v-divider>
                <v-subheader>
                  Client Activity {{ activities.id_sequence }} -
                  {{ activities.activity_type }}

                  <v-spacer></v-spacer>

                  <v-menu>
                    <!--Provides the three dot menu so that the user can add/Remove Activities/Goods 
          from the activity menu-->
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(ActivityButtons, i) in ActivityButtons" :key="i">
                        <v-btn class="AddActivity" :color=ActivityButtons.Color plain small>{{ ActivityButtons.title }}
                        </v-btn>

                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-subheader>
                <v-select label="Activity Type" v-model="activities.activity_type" :items="CAType" dense>
                </v-select>
                <v-text-field class="CaAddress" label="Address" name="CaAddress" id="CaAddress" for="CaAddress"
                  :rules="mandatory" v-model="activities.address.street_1" required></v-text-field>
                <v-text-field class="PostCode" label="Postal code" name="PostCode" id="PostCode" for="PostCode"
                  v-model="activities.address.zipcode" :rules="mandatory" required></v-text-field>
                <v-text-field class="City" label="City" name="City" id="City" for="City"
                  v-model="activities.address.city" :rules="mandatory" required></v-text-field>
                <v-text-field class="Country" label="Country" name="Country" id="Country" for="Country"
                  v-model="activities.address.country" :rules="mandatory" required></v-text-field>
                <v-text-field class="RefrenceNumber" label="Refrence Number" name="RefrenceNumber" id="RefrenceNumber"
                  for="RefrenceNumber" v-model="RefrenceNumber" required></v-text-field>
                <v-row justify="center">
                  <v-col cols="6">
                    <DatePicker :date="activities.date" />
                  </v-col>
                  <v-col cols="6">
                    <TimePicker timeState="Time From" :time="activities.time_1" />
                  </v-col>
                  <v-col offset-md="6">
                    <TimePicker timeState="Time Until" :time="activities.time_2" />
                  </v-col>
                </v-row>
                <v-textarea name="driverInstructions" id="driverInstructions" class="DriverInstructions"
                  label="Driver Instructions" v-model="activities.instructions" outlined></v-textarea>
              </v-item-group>

              <!-- This section goes throughout the goods that are in the activity mentioned above 
              -->
              <div v-for="good in activities.goods" :key="good.id">
                <v-item-group>
                  <v-divider></v-divider>
                  <v-subheader>Goods {{ good.id }} to Client Activity
                    {{ good.activity_id }} - {{ activities.activity_type }}
                    <v-spacer></v-spacer>
                    <v-menu>
                      <!--Provides the three dot menu so that the user can add/Remove Activities/Goods 
          from the activity menu-->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item v-for="(ActivityButtons, i) in ActivityButtons" :key="i">
                          <v-btn class="AddActivity" :color=ActivityButtons.Color plain small>{{ ActivityButtons.title }}
                          </v-btn>

                        </v-list-item>
                      </v-list>
                    </v-menu>

                  </v-subheader>
                  <v-text-field class="quantity" label="Quantity" name="quantity" id="quantity" for="quantity"
                    v-model="good.quantity" :rules="mandatory" required></v-text-field>
                  <v-text-field class="unit" label="Unit" name="unit" id="unit" for="unit" v-model="good.unit_type"
                    :rules="mandatory" required></v-text-field>
                  <v-textarea name="goodsDescription" id="goodsDescription" class="goodsDescription"
                    label="Description of Goods" v-model="good.description" outlined></v-textarea>
                  <v-text-field class="grossWeight" label="Gross Weight" name="grossWeight" id="grossWeight"
                    for="grossWeight" v-model="good.gross_weight" append-icon="mdi-weight-kilogram"
                    :rules="[numberRule]">
                  </v-text-field>
                  <v-text-field class="NettWeight" label="Nett Weight" name="NettWeight" id="NettWeight"
                    for="NettWeight" v-model="good.net_weight" append-icon="mdi-weight-kilogram" :rules="[numberRule]">
                  </v-text-field>
                  <v-text-field class="loadingMeters" label="Loading Meters" name="loadingMeters" id="loadingMeters"
                    for="loadingMeters" v-model="good.loading_meters" append-icon="m" :rules="[numberRule]" required>
                  </v-text-field>
                  <v-row class="measurementsOfGoods">
                    <v-col>
                      <v-text-field class="goodsWidth" label="Width" name="goodsWidth" id="goodsWidth" for="goodsWidth"
                        v-model="goodsWidth" append-icon="m"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field class="goodsHeight" label="Height" name="goodsHeight" id="goodsHeight"
                        for="goodsHeight" v-model="goodsHeight" append-icon="m"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field class="goodsLength" label="Length" name="goodsLength" id="goodsLength"
                        for="goodsLength" v-model="goodsLength" append-icon="m"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="good.stackable" label="Stackable"></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-model="good.dangerous_goods" label="Dangerous Goods"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-item-group>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-col>
          <div class="d-flex flex-row">
            <v-spacer></v-spacer>
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" color="error" fab plain>
                  cancel
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7">
              <v-btn color="warning" rounded disabled @click="reset">
                Reset Form
              </v-btn>
            </div>
            <div class="d-flex flex-column ml-7">
              <v-btn :disabled="!valid" color="success" rounded @click="validateFields">
                Validate
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-col>
    </v-form>
  </v-app>
</template>
<style scoped lang="scss">
.loading-circle-container {
  z-index: 100;
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding-top: 300px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);
  border-radius: 10px;
}
</style>
<script>
import ButtonGroup from "../InProgressComponents/ButtonGroup.vue";
import DatePicker from "../InProgressComponents/DatePicker.vue";
import TimePicker from "../InProgressComponents/TimePicker.vue";
import dialogComponentVue from "../dialogComponent.vue";

export default {
  name: "fileInfoCard",

  components: {
    ButtonGroup,
    DatePicker,
    TimePicker,
    dialogComponentVue,
  },
  //checks if the params has anything in it if not restarts the page and sends you back to orders
  mounted() {
    if (this.$route.params.transport_order_id == null) {
      console.log("error loading data");
      this.$router.push("orderlist")
    }
    else {
      this.transport_order_id = this.$route.params.transport_order_id;
      this.getTransportData();
      console.log(this.$route.params.transport_order_id);
    }
  },
  created() {
    window.addEventListener('beforeunload', function (event) {
      event.returnValue = ''
    })
  },

  methods: {
    //REQUIRED FOR THIS PAGE 
    //prevents user from restarting page
    preventNav(event) {
      if (!this.isEditing) return
      event.preventDefault()
      event.returnValue = ""
    },
    // submit the validation
    //checks the fields if they answer to the validation standards
    validateFields() {
      if (this.$refs.form.validate()) {
        // this.$router.push("orderlist");
        this.submit();
        this.snackbar = true;
        console.log('form validated');
      }
      else {
        console.log('form failed to validate')
      }
    },
    // reset the validation
    reset() {
      this.$refs.form.reset();
    },
    //takes the transport data from the api
    getTransportData() {
      this.transportData = [];
      this.loadedOrderInfo = {};
      this.$api
        .get("/transport_file/" + this.transport_order_id, {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            "X-Authorization": this.$session.get("token")
          },
        })
        .then((res) => {
          this.transportData = res.data;
          this.loadLoading();
          this.isLoaded = false;
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //puts the json file to the backend. Doesn't work
    submit() {
      const URL = 'https://logichange-backend-test.herokuapp.com/transport_file/' + this.transport_order_id;
      // PUT request using fetch with set headers
      this.$api
        .put(URL, this.loadedOrderInfo)
        .then(() => {
          JSON.stringify(this.loadedOrderInfo),
            console.log(this.loadedOrderInfo);
        })
        .catch((error) => {
          console.log(error.response);
        });

    },
    //made for editing the incoming information
    loadLoading() {
      this.loadedOrderInfo = this.transportData;
    },
  },

  data() {
    return {

      //General information and rules
      isEditing: false, //used to display popup when page is restarted
      transport_order_id: "",
      valid: true,
      mandatory: [(v) => !!v || "This field is mandatory"],
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "This field is numbers only";
      },

      //File Information
      defaultSelected: "",
      CAType: ["Load", "Deli"],
      CATyppeState: "",
      clientName: "",
      clientContact: "",
      invoiceRef: "",
      fileType: "Transport",
      toggle_exclusive: undefined,

      //Client Activity information
      ActivityButtons: [
        { title: 'Add Activity', Color: "success", method: "" },
        { title: 'Remove Activity', Color: "error", method: "" },
        { title: 'Add Goods', Color: "success", method: "" },
        { title: 'Remove Goods', Color: "error", method: "" },
      ],

      ActivityNumber: "",
      CaAddress: "",
      PostCode: "",
      City: "",
      Country: "",
      RefrenceNumber: "",
      format: "dddd MMMM Do, YYYY",
      date: "",
      driverInstr: {
        text: ``,
      },

      //Goods information
      GoodsNumber: "",
      quantity: "",
      unit: "",
      goodsDescription: {
        text: ``,
      },
      grossWeight: "",
      nettWeight: "",
      loadingMeters: "",
      goodsWidth: "",
      goodsHeight: "",
      goodsLength: "",
      checkboxStackable: false,
      checkboxADR: false,
      message: {
        text: ``,
      },
      submitted: false,



      // anIncreasingNumber: 1,
      // display_number: "",
      transportData: [],
      isLoaded: true,
      loadedOrderInfo: {},
      // end required for this page
    };
  },

};

</script>
