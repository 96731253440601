<template>
    <v-app>
      <div class="btn-container">
        <v-btn class="btn-allocate mr-3" color="error" v-if="show_details" @click="hideDetails">Cancel</v-btn>
        <v-btn class="btn-allocate" color="primary">Allocate orders</v-btn>
      </div>
        <v-data-table 
        :headers="headers" 
        :items="loadings" item-key="display_number" 
        :items-per-page="10" 
        :search="search"
        :loading="isLoaded" 
        loading-text="Fetching Data... Please wait." 
        class="elevation-1 cranberry"
        v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }" :key="anIncreasingNumber">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        </v-data-table>
        <template v-slot:item.Certainty="{ item }">
          <v-chip :color="getColor(item.calories)" dark> </v-chip>
        </template>
    </v-app>
  </template>
  
  <style>
  .cranberry {
    margin-top: 2%;
  }
  
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .btn-allocate {
    max-width: 160px;
    transform: translateY(-5px);
    box-shadow: none !important;
  }
  
  .main-container {
    display: flex;
  }
  
  .popup-form {
    transform: translateX(-100%);
  }
  </style>
  
  <script>
  import Sortable from "sortablejs";
  export default {
    components: {
    },
    data() {
      return {
        transport_order_id: "",
        anIncreasingNumber: 1,
        search: "",
        headers: [
          {
            text: "Certainty",
            align: "start",
            sortable: false,
            value: "",
          },
          { text: "Order #", value: "display_number" },
          { text: "Client Ref.", value: "client_id" },
          { text: "MT", value: "" },
          { text: "MA", value: "" },
          { text: "DD", value: "" },
          { text: "Urgency", value: "" },
          { text: "LB", value: "" },
          { text: "Date", value: "date_load" },
          { text: "Ctry", value: "country_load" },
          { text: "Zip code", value: "zip_load" },
          { text: "Loading", value: "street_1_load" },
          { text: "Name (from)", value: "name_load" },
          { text: "Date", value: "date_deli" },
          { text: "Ctry", value: "country_deli" },
          { text: "Zip code", value: "zip_deli" },
          { text: "Unloading", value: "street_1_deli" },
          { text: "Name (to)", value: "name_deli" },
          { text: "Volume", value: "loading_meters" },
          { text: "L/C", value: "" },
          { text: "Unit", value: "unit_type" },
          { text: "Gr wt", value: "grwt" },
          { text: "ADR", value: "dangerous_goods" },
        ],
        transportData: [],
        transportDataById: [],
        client_ref: "",
        isLoaded: true,
        loadings: [],
        display_number: "",
      };
    },
    mounted() {
      this.getTransportData();
    },
    methods: {
      goToClustering() {
        this.$router.push("clustering")
      },
      getTransportDataById(id) {
        this.transportDataById = [];
        this.loadedOrderInfo = {};
        this.$api
          .get("/transport_file/" + id)
          .then((res) => {
            this.transportDataById = res.data;
            this.loadLoading();
            this.isLoaded = false;
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getTransportData() {
        this.transportData = [];
        this.loadings = [];
        this.$api
          .get("/transport_file/get_by_status/clustering", {
            headers: {
              "X-Authorization": this.$session.get("token"),
            },
          })
          .then((res) => {
            this.transportData = res.data;
            this.loadLoading();
            this.isLoaded = false;
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getColor(certainty) {
        if (certainty < 500) return "red";
        else if (certainty > 800) return "orange";
        else return "green";
      },
      loadLoading() {
        for (const key in this.transportData) {
          const temp = {
            transport_order_id: this.transportData[key].id,
            display_number: this.transportData[key].display_number,
            client_id: this.transportData[key].client.client_identifier,
          };
          for (const loadKey in this.transportData[key].activities) {
            for (const goodsKey in this.transportData[key].activities[loadKey].goods) {
              temp.grwt = this.transportData[key].activities[loadKey].goods[goodsKey].gross_weight;
              temp.dangerous_goods = this.transportData[key].activities[loadKey].goods[goodsKey].dangerous_goods;
              temp.loading_meters = this.transportData[key].activities[loadKey].goods[goodsKey].loading_meters;
              temp.unit_type = this.transportData[key].activities[loadKey].goods[goodsKey].unit_type;
            }
            if (
              this.transportData[key].activities[loadKey].activity_type == "Load"
            ) {
              temp.street_1_load =
                this.transportData[key].activities[loadKey].address.street_1;
              temp.date_load = this.transportData[key].activities[loadKey].date;
              temp.country_load =
                this.transportData[key].activities[loadKey].address.country;
              temp.zip_load =
                this.transportData[key].activities[loadKey].address.zipcode;
              temp.name_load = this.transportData[key].client.name;
            }
            if (
              this.transportData[key].activities[loadKey].activity_type == "Deli"
            ) {
              temp.street_1_deli =
                this.transportData[key].activities[loadKey].address.street_1;
              temp.date_deli = this.transportData[key].activities[loadKey].date;
              temp.country_deli =
                this.transportData[key].activities[loadKey].address.country;
              temp.zip_deli =
                this.transportData[key].activities[loadKey].address.zipcode;
              temp.name_deli = this.transportData[key].client.name;
            }
          }
          //console.log(temp);
          this.loadings.push(temp);
        }
      },
      sortTheHeadersAndUpdateTheKey(evt) {
        const headersTmp = this.headers;
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1;
          while (k--) {
            headersTmp.push(undefined);
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
        this.table = headersTmp;
        this.anIncreasingNumber += 1;
      },
    },
    directives: {
      "sortable-table": {
        inserted: (el, binding) => {
          el.querySelectorAll("th").forEach((draggableEl) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            //watchClass(draggableEl, 'sortHandle');
            draggableEl.classList.add("sortHandle");
          });
          Sortable.create(
            el.querySelector("tr"),
            binding.value ? { ...binding.value, handle: ".sortHandle" } : {}
          );
        },
      },
    },
  };
  </script>