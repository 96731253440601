<template>
  <v-app>
    <v-card class="rounded-card" outlined elevation="2" height="auto">
      <v-toolbar height="18%" flat>
        <template>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider></v-tabs-slider>
            <v-tab> Pending </v-tab>
            <v-tab> In progress </v-tab>

          </v-tabs>
        </template>
        <v-btn color="primary" dark :loading="isSelecting" @click="handleFileImport">
          Upload order
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged">
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-list>
            <v-list-item-group>
              <pendingOrdersTable />
            </v-list-item-group>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list>
            <v-list-item-group>
              <inProgressTable />
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-app>
</template>
<style>
.v-card {
  color: #fdfdfd;
  padding: 2%;
}

.v-btn {
  transform: translateY(-10px);
}
</style>


<script>
import pendingOrdersTable from "../components/OrderViewComponents/pendingOrdersTable.vue";
import inProgressTable from "./OrderViewComponents/inProgressTable.vue";
export default {
  name: "App",

  components: {
    pendingOrdersTable,
    inProgressTable
  },

  data() {
    return {
      tab: null,
      isSelecting: false,
      selectedFile: null,
    };
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true });

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      var formData = new FormData();
      formData.append('file', this.selectedFile);

      // Do whatever you need with the file, liek reading it with FileReader
      this.$fileApi.post('https://0b90-144-178-204-116.eu.ngrok.io/upload', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });
      console.log(this.selectedFile);
    },
  }
};
</script>
