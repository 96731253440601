import Vue from 'vue'
import VueRouter from 'vue-router'
import OrderList from '../views/OrderList.vue'
import InProgress from '../views/InProgress.vue'

Vue.use(VueRouter)



const routes = [{
        path: '/',
        name: 'Home',
        component: OrderList
    },
    {
        path: '/progress',
        name: 'InProgress',
        component: InProgress,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router