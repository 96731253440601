<template>
  <v-app>
    <div class="header2">
      <img src="../assets/Logichainge_logo_white.png" alt="" class="logo2">
    </div>
    
    <OrderNavigator />

    <div class="footer">
      <img src="../assets/logo.svg" alt="" class="footer-logo">
    </div>
  </v-app>
</template>

<style>
.dragonFruit {
  position: absolute;
  top: 3vh;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: .1666666667em;
  color: gray;
}

.joghurt {
  padding-top: 10vh;
  padding-right: 10vh;
}

.header2 {
  width: 100%;
  height: 150px;
  background-color: var(--brand-color);
  text-align: center;;
}

.logo2 {
  height: 150px;
}

.footer {
  width: 100%;
  height: 70px;
  background-color: var(--brand-color);
  text-align: end;

}

.footer-logo {
  height: 50px;
  padding-right: 50px;
  margin-top: 10px;
}
</style>
<script>
import OrderNavigator from "../components/orderNavigator";

export default {
  name: "OrderList",
  components: {
    OrderNavigator,
  },
};</script>